import { useContext, useEffect, useState } from "react"
import { Checkbox, FormControlLabel, FormLabel } from "@mui/material"
import { ModuleComponentsTextFieldComponent } from "../components/text-field.component"
import { isChecked, onCheckChangeEvent } from "../submodule.base"
import { ModuleComponentsIncrementProviderComponentContext } from "../provider/increment.provider"
import { ModuleComponentsRadioComponent } from "../components/radio.component"
import { ModuleComponentsTextareaComponent } from "../components/textarea.component"
import { ModuleSubmoduleLocations } from "./locations"
import { ModuleComponentsDatePickerComponent } from "../components/data-picker.component"
import { count, equals, exists, nowDate } from "../../../helpers/util.helper"
import { ApplicationContext } from "../../../providers/application.provider"
import { ModuleSubmoduleBaseContext } from "../../../providers/submodulebase.provider"
import { ModuleComponentsGeneticsComponent } from "../components/genetics"
import { IAnyType } from "../../../interfaces/form-builder.interface"

export interface IModuleSubmoduleCalvingGroups
{
  groupType: string
  certsSelected: string[] | number[]
  certsAvailable: any[]
}

export const ModuleSubmoduleCalvingGroups = ({ groupType, certsSelected }: IModuleSubmoduleCalvingGroups) => {
    const { editable, currentApp } = useContext(ApplicationContext)
    const [ ready, setReady ] = useState<boolean>(false)
    const [ form, setForm ] = useState<any>(currentApp?.answers || {})
    const [ formBuilt, setFormBuilt ] = useState<IAnyType<any>>({})
    const { parentName } = useContext(ModuleSubmoduleBaseContext)
    const { i, defaultData } = useContext(ModuleComponentsIncrementProviderComponentContext)

    const keyName = parentName || ''

    let preset = [ i, keyName ].join('.').replace('..', '.')

    if(preset === '.')
      preset = ''

    const getTagsData = (key: string, type: string = '') => {
      const def = {
        value: '',
        active: false
      }
      const t = currentApp?.answers || {}
      if(count(t) === 0) {
        return def
      }

      if(typeof t[type] === "undefined")
        return def

      if(typeof t[type][key] !== "undefined") {
        def.active = t[type][key] !== ''
        def.value = t[type][key]
      }
      return def
    }
    
    const getValueData = (key: string, type: string = '') => {
      const def = {
        value: '',
        active: false
      }
      const t = currentApp?.answers || {}
      if(count(t) === 0) {
        return def
      }
      if(type !== '') {
        if(typeof t[type] === "undefined")
          return def

        if(typeof t[type][key] !== "undefined") {
          def.active = t[type][key] !== ''
          def.value = t[type][key]
        }
      } else {
        if(typeof t[key] === "undefined")
          return def

        if(typeof t[key] !== "undefined") {
          def.active = t[key] !== ''
          def.value = t[key]
        }
      }
      return def
    }
    const getName = (n: string) => `${preset !== ''? `${preset}.` : ''}${n}`

    let curr = 'tags_applied'
    const tagsApplied = [
        {
          "value": "calving",
          "label": "at Calving",
          "name": "tags_applied.calving",
          "defaultValue": getTagsData('calving', curr).active,
        },
        {
          "value": "branding",
          "label": "at Branding",
          "name": "tags_applied.branding",
          "defaultValue": getTagsData('branding', curr).active
        },
        {
          "value": "weaning",
          "label": "at Weaning",
          "name": "tags_applied.weaning",
          "defaultValue": getTagsData('weaning', curr).active
        },
        {
          "value": "yearling",
          "label": "Yearling",
          "name": "tags_applied.yearling",
          "defaultValue": getTagsData('yearling', curr).active
        },
        {
          "value": "other",
          "label": "Other",
          "toggle": "calving_group_tag_when_applied_desc.text",
          "name": "tags_applied.other",
          "defaultValue": getTagsData('other', curr).active,
          "child": {
            "type": "textarea",
            "required": true,
            "name": "tags_applied.other_desc",
            "toggle_target": "calving_group_tag_when_applied_desc.text",
            "label": "Other",
            "placeholder": "Please Describe",
            "value": "",
            "visible": getTagsData('other', curr).active,
            "defaultValue": getTagsData('other_desc', curr).value
          }
        }
      ]

    curr = 'calving_tags'
    const tags = [
        {
          "value": "eid",
          "label": "USDA approved 15-digit Animal Identification Number (AIN) as PCT EID, RFID, or Visual tag",
          "toggle": "calving_group_tag_eid_desc.text",
          "name": "tags.eid.yes",
          "checked": true,
          "child": {
            "type": "text",
            "required": true,
            "name": "tags.eid.desc",
            "toggle_target": "calving_group_tag_eid_desc.text",
            "label": "Who do you purchase this tag from?",
            "value": "",
            "visible": getTagsData('eid', curr).active,
            "defaultValue": getTagsData('eid', curr).value,
          }
        },{
          "label": <p className="legal"><i>If an animal loses a PCT, and its identity can be verified through a secondary identification, a new PC tag may be applied. A secondary form of identification such as listed below must be selected to ensure the accuracy of a potential need for re-tagging.</i></p>
        },
        {
          "value": "metalplastic_clip",
          "label": "USDA Brucellosis Calfhood Vaccination tags (orange metal ear tags)",
          "toggle": "calving_group_tag_metalplastic_clip_desc.text",
          "name": "tags.metalplastic_clip.yes",
          "child": {
            "type": "text",
            "required": true,
            "name": "tags.metalplastic_clip.desc",
            "toggle_target": "calving_group_tag_metalplastic_clip_desc.text",
            "label": "Who do you purchase this tag from?",
            "value": "",
            "visible": getTagsData('metalplastic_clip', curr).active,
            "defaultValue": getTagsData('metalplastic_clip', curr).value,
          }
        },
        {
          "value": "ear_notches",
          "label": "Hang Tag",
          "toggle": "calving_group_tag_ear_notches_desc.text",
          "name": "tags.ear_notches.yes",
          "child": {
            "type": "text",
            "required": true,
            "name": "tags.ear_notches.desc",
            "toggle_target": "calving_group_tag_ear_notches_desc.text",
            "label": "Describe Your Tag (Numbering Scheme, Color of Tag, etc.) *",
            "value": "",
            "visible": getTagsData('ear_notches', curr).active,
            "defaultValue": getTagsData('ear_notches', curr).value,
          }
        },
        {
          "value": "brands",
          "label": "Breed association registration tattoo, freeze or fire brand + reg. paper",
          "toggle": "calving_group_tag_brands_desc.text",
          "name": "tags.brands.yes",
          "child": {
            "type": "text",
            "required": true,
            "name": "tags.brands.desc",
            "toggle_target": "calving_group_tag_brands_desc.text",
            "label": "Describe Brand/Marking (Ex. quarter circle on left hip)",
            "value": "",
            "visible": getTagsData('brands', curr).active,
            "defaultValue": getTagsData('brands', curr).value,
          }
        },
        // {
        //   "value": "ranch",
        //   "label": "Ranch Tag/Visual Tag/Panel Tag",
        //   "toggle": "calving_group_tag_ranch_desc.text",
        //   "name": "tags.ranch.yes",
        //   "child": {
        //     "type": "text",
        //     "required": true,
        //     "name": "tags.ranch.desc",
        //     "label": "Describe Ranch Tag (Numbering Scheme, Color of Tag, etc.)",
        //     "value": "",
        //     "visible": getTagsData('ranch', curr).active,
        //     "toggle_target": "calving_group_tag_ranch_desc.text",
        //     "defaultValue": getTagsData('ranch', curr).value,
        //   }
        // },
        {
          "value": "other",
          "label": "Other",
          "toggle": "calving_group_tag_other_tag_desc.text",
          "name": "tags.other_tag.yes",
          "child": {
            "type": "text",
            "required": true,
            "name": "tags.other_tag.desc",
            "toggle_target": "calving_group_tag_other_tag_desc.text",
            "label": "Describe Your Tag (Numbering Scheme, Color of Tag, etc.)",
            "value": "",
            "visible": getTagsData('other_tag', curr).active,
            "defaultValue": getTagsData('other_tag', curr).value,
          }
        }
    ]

    const setFormByObjDefValues = (): any => {
      const f: any = {}
      tagsApplied.map(field => {
        if(field.defaultValue) {
          f[`${preset !== ''? `${preset}.` : ''}${field.name}`] = field.defaultValue
        }
      })

      tags.map(field => {
        if(field.child) {
          f[`${preset !== ''? `${preset}.` : ''}${field.name}`] = field.child.visible
          f[`${preset !== ''? `${preset}.` : ''}${field.name.replace('yes', 'desc')}`] = field.child?.defaultValue
        }
      })
      return f
    }

    useEffect(() => {
      if(!ready && !formBuilt.fieldsReady) {
        // if(count(defaultData || {}) > 0) {
        //   const f = setFormByObj(defaultData)
        //   if(count(f)) {
        //     setForm(f)
        //   }
        // }

        if(count(currentApp?.answers || {}) > 0) {
          const f = setFormByObjDefValues()
          const v = {
            [getName(`group_type`)]: getValueData('group_type').value,
            [getName(`group_name_description`)]: getValueData('group_name').value,
            [getName(`cattle_left_origin.yes`)]: getValueData('cattle_left_origin').active? '1' : '0',
            [getName(`cattle_left_origin.desc`)]: getValueData('cattle_left_origin').value,
            [getName(`born.start_date`)]: getValueData('first_born_date').value,
            [getName(`born.end_date`)]: getValueData('last_born_date').value,
            [getName(`approx_calves`)]: getValueData('approx_calves').value,
            [getName(`id_description`)]: getValueData('id_description').value,
          }
          Object.keys(v).map(r => {
            f[r] = v[r]
          })
          
          if(count(f)) {
            setForm(f)
          }
        }
        setReady(true)
        setFormBuilt({...formBuilt, fieldsReady: true})
      }
    }, [ defaultData, currentApp?.answers, formBuilt, ready ])

    useEffect(() => {
      if(formBuilt.fieldsReady && !formBuilt.tagsReady) {
        let f: any = {...form}
        tags.map((v) => {
          if(v.checked) {
            f[v.name] = true
          }
        })
        setForm(f)
        setFormBuilt({...formBuilt, tagsReady: true})
      }
    }, [ formBuilt ])

    return (
      formBuilt.fieldsReady && formBuilt.tagsReady? <>
            <div className="col-count- gapped">
                <input name="group_type" value={groupType} type="hidden" />
                <ModuleComponentsTextFieldComponent
                    label="Group Name"
                    required
                    name={ getName(`group_name_description`) }
                    disabled={!editable}
                    placeholder={`Example: Spring ${+nowDate('Y') + +1}`}
                    defaultValue={ getValueData('group_name').value }
                />

                <ModuleComponentsRadioComponent
                    label="Will the cattle leave the origin or premise after birth and prior to shipping date?"
                    setForm={ setForm }
                    form={ form }
                    defaultValue={ getValueData('cattle_left_origin').active? '1' : '0' }
                    disabled={ !editable }
                    name={ getName('cattle_left_origin.yes') }
                />
                { equals(form[`${preset !== ''? `${preset}.` : ''}cattle_left_origin.yes`], "1")? 
                <ModuleComponentsTextareaComponent
                    label="Please describe when they left and where they went:"
                    name={ getName('cattle_left_origin.desc') }
                    defaultValue={ getValueData('cattle_left_origin').value }
                    className="standard"
                    disabled={ !editable }
                    required
                /> : null }

                <ModuleSubmoduleLocations />
                
                {
                    [{
                        "type": "datepicker",
                        "name": getName(`est_ship_date`),
                        "label": "Estimated Ship Date",
                        "value": "",
                        "required": true,
                        "defaultValue": getValueData('est_ship_date').value || ''
                    },{
                        "type": "datepicker",
                        "name": getName(`born.start_date`),
                        "label": "Date of First Calf Born",
                        "value": "",
                        "required": true,
                        "defaultValue": getValueData('first_born_date').value || ''
                    },
                    {
                        "type": "datepicker",
                        "name": getName('born.end_date'),
                        "label": "Date of Last Calf Born",
                        "value": "",
                        "required": true,
                        "defaultValue": getValueData('last_born_date').value
                    }].map((v, k) => (
                        <div key={k}><ModuleComponentsDatePickerComponent
                            required={ v.required || true }
                            label={ v.label }
                            name={ v.name }
                            defaultValue={ v.defaultValue }
                        /></div>
                    ))
                }

                <ModuleComponentsTextFieldComponent
                    title="Approx. # Calves"
                    name={ getName('approx_calves') }
                    defaultValue={ getValueData('approx_calves').value }
                    className="required"
                    replace="[^\d]"
                    disabled={!editable}
                    required
                />
                </div>

                <div className="col-count- gapped">
                <h5 className="mt-3">Tag Information</h5>

                {/* <ModuleComponentsTextareaComponent
                    label="Describe calves identification (i.e. blue ranch tag at birth with cow's #, brand in spring, etc.)"
                    name={ getName('id_description') }
                    defaultValue={ getValueData('id_description').value }
                    className="standard"
                    required
                    disabled={!editable}
                /> */}

                {
                    tags.map((v,k) => {
                        return (
                        <div key={`${preset !== ''? `${preset}.` : ''}${k}`}>
                          { v?.label && !v?.name? <div><FormLabel>{v.label}</FormLabel></div> : null }

                          { v?.name? 
                            <>
                            <FormControlLabel
                                onChange={(e) => onCheckChangeEvent(e, setForm) }
                                checked={ v.checked || isChecked(form, getName(v.name)) }
                                name={ getName(v.name) }
                                value='1'
                                control={<Checkbox />}
                                label={v.label}
                                disabled={ !editable }
                            />
                            { (v.child && (v.checked || isChecked(form, getName(v.name))))? 
                                <ModuleComponentsTextFieldComponent
                                    label={v.child.label}
                                    name={ getName(v.child.name) }
                                    className="required"
                                    defaultValue={ form[getName(v.child.name)] || v.child.defaultValue || ''}
                                    disabled={ !editable }
                                    required
                                /> : null
                            }
                            </> : null }
                        </div>
                    )})
                }
                <FormLabel>When will PCT EID tags or other program compliant tags be applied?</FormLabel>
                {
                    tagsApplied.map((v, k) => (
                        <div key={`tags-applied-${k}`}>
                            <FormControlLabel
                                onChange={(e) => onCheckChangeEvent(e, setForm) }
                                checked={ isChecked(form, getName(v.name)) }
                                name={ getName(v.name) }
                                value='1'
                                control={<Checkbox />}
                                label={v.label}
                                disabled={ !editable }
                            />
                            { (v.child && isChecked(form, getName(v.name)))? 
                            <ModuleComponentsTextareaComponent
                                label={ v.child.label }
                                name={ getName(v.child.name) }
                                defaultValue={ v.child.defaultValue || ''}
                                className="standard"
                                required
                                disabled={ !editable }
                            /> : null }
                        </div>
                    ))
                }

                { exists(certsSelected, 'gvab')? 
                  <ModuleComponentsGeneticsComponent parentName="gvab" />
                : null }

            </div>
        </> : null
    )
}